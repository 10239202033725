.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.vPad {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hPad {
  padding-left: 10px;
  padding-right: 10px;
}

.loginContainer {
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: auto 200px auto;
  column-gap: 10px;
  row-gap: 10px;
  height: 100vh;
 }

#loginform {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;

  display:grid;
  row-gap: 25px;
}

.container {
  text-align: left;
}

.noteText {
  font-weight: bold;
  color: red;
}

.tipText {
  font-weight: italic;
  color: blue;
}

.queueTable {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  width: 100%;
}

.queueTableRow {
  display: grid;
  grid-template-columns: 13% 13% 27% 27% 10% 10%;
}

.queueTableRowHigh {
  display: grid;
  font-weight: bold;
  color: red;
  grid-template-columns: 13% 13% 27% 27% 10% 10%;
}

.queueTableRowCorporate {
  display: grid;
  font-weight: bold;
  color: darkblue;
  grid-template-columns: 13% 13% 27% 27% 10% 10%;
}
.queueTableHeader {
  display: grid;
  grid-template-columns: 13% 13% 27% 27% 10% 10%;
  padding: 1.0rem;
}

.queueTableReview {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  width: 100%;
}

.queueTableRowReview {
  display: grid;
  grid-template-columns: 15% 15% 30% 20% 20%;
}

.queueTableHeaderReview {
  display: grid;
  grid-template-columns: 5% 24% 31% 20.5% 19.5%;
  padding: 1.0rem;
}

.queueNum {
  text-align: center;
}

.pageTitle {
  display: grid;
  grid-template-columns: 25% 25% 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.reviewEditorTitle {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 20px;
}

.section {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padded {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.itemDetails {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.queueItemDetails {
  display: grid;
  grid-template-columns: 33% 34%;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.2rem;
  width: 100%;
  padding: 1rem 0;
}

.searchItemDetails {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.customItemDetails {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nutrientsTable {
  display: grid;
  grid-template-columns: 55% 40%;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-top: solid gray 1px;
}

.columnsTable {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: solid gray 1px;
  border-bottom: solid gray 1px;
}

.splitPane {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 2rem;
  grid-auto-flow: dense;
  grid-template-rows: auto 1fr auto;
  /* overflow-y: scroll; native css scrolling couldn't handle changing size of component */
  border-top: solid gray 2px;
  /* border-bottom: solid gray 2px; */
}

.splitPane #details {
  align-self: start;
  width: 600px;
}

.splitPane #detailsReview {
  grid-row: 1;
  align-self: start;
  width: 600px;
}

.splitPane #asset {
  align-self: start;
}

.splitPane #assetReview {
  grid-row: 2;
  align-self: start;
}

.splitPane #summary {
  align-self: end;
}

.splitPane #rightPane {
  grid-column: 2;
  grid-row: 1/5;
}

.splitPane #predictedItemsView {
  grid-row: 2;
}

.twoColTable {
  display: grid;
  grid-template-columns: 45% 55%;
  row-gap: 0.5rem;
}

.pushToRight {
  display: grid;
  grid-template-columns: 80% 20%;
  row-gap: 0.5rem;
}

.titleOption {
  padding-top: 1rem;
  font-weight: bold;
  font-size: 1.4rem;
}

.sectionTitle {
  grid-column-start: span 3;
  font-weight: bold;
  font-size: 1.4rem;
}

.subSectionTitle {
  grid-column-start: span 3;
  font-weight: bold;
  font-size: 1rem;
}

.fieldName {
  font-size: 1rem;
  font-weight: bold;
}

.fieldValue {
  grid-column-start: span 2;
}

.fieldFull {
  grid-column-start: span 3;
}

.queueImage {
  max-width: 100%;
  max-height: 600px;
}

.queueImageFullScreen {
  display:block;
  width:100%; height:100%;
  object-fit: cover;
}

.reviewImage {
  width: 100%;
  max-height: 600px;
}

.fullScreenDiv {
  opacity: 1;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
}

.queueImageDiv {
  opacity: 1;
  position: relative;
  z-index:1000;
  width: 100%;
  max-width: 600px;
  max-height: 600px;
}

.fullScreenButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 30px !important;
  height: 30px !important;
}

.mealItemExpandButton {
  width: 20px !important;
  height: 20px !important;
}

.historyImage {
  width: 30%;
}

table.table-black-border,
table.table-black-border th,
table.table-black-border td {
  border: 1px solid black;
  padding: 5px 15px 5px 15px;

}

.addOnTable {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  font-size: 8;
  padding-left: 0.5rem;
}

.searchOption {
  padding-left: 10px;
}

.searchOption:hover {
  background-color: antiquewhite;
}

.newItemBtn {
  grid-column-start: span 3;
}

.mealItemEditor {
  background-color: beige;
  display: grid;
  grid-column-start: span 3;
  /* grid-template-columns: 40% 60%; */
  grid-template-rows: auto;
  grid-auto-flow: row;
  line-height: 2;
  width: 95%
}

.addOnList {
  display: grid;
  grid-template-columns: 50% 50%
}

.patientNoteField {
  display: flex;
  flex-direction: row;
}

.patientNoteTextArea {
  border-radius: 5px;
  padding: 6px 6px 6px 6px;
  border: 2px solid #0b5ed7;
}
.patientNoteButton {
  border-radius: 7px;
  outline: none;
  padding: 0.6em;
  padding-bottom: 0.5em;
  min-width: 30px;
  height: 100%;
  border: none;
  background-color: rgb(227, 227, 227);

}
.patientNoteButton:hover {
  background-color: #d9d9d9;
}

.patientNoteButton.apply {
  color: #FFF;
  background: #3ec64d;
  border: 2px solid #3ec64d;
  margin-right: 3px;
}
.patientNoteButton.apply:hover {
  background: #58b558;
  border: 2px solid #58b558;
}

.patientNoteButton.revert {
  color: darkred;
  border: 2px solid darkred;
  background: transparent;
}
.patientNoteButton.revert:hover {
  background: #fbe6e6;
}


.servingOptionsEditField {
  width: 300px;
}

.servingUnitAmountEditField {
  width: 300px;
}

.noteEditField {
  width: 23rem;
}

.searchField {
  width: 20rem;
  border: 0px;
}

.searchButton {
  width: 10rem;
  height: 3rem;
  margin-top: 0.5rem;
  color: white;
  background: dodgerblue;
}

.summaryView {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.summaryTableHeader {
  display: grid;
  grid-template-columns: 60% 20% 10% 10%;
  row-gap: 0.25rem;
  grid-column-gap: 10px;
  padding-bottom: 0.25rem;
  border-bottom: solid black 1.5px;
  font-size: 12px;
}

.summaryTable {
  padding-bottom: 0.25rem;
  border-bottom: solid black 1.5px;
  font-size: 12px;
}

.summaryTable-odd {
  padding-bottom: 0.25rem;
  border-bottom: solid black 1.5px;
  font-size: 12px;
  background-color: #e4e4e4;
}

.summaryTableSubrow {
  width: 100%;
  display: grid;
  grid-template-columns: 60% 20% 10% 10%;
  row-gap: 0.25rem;
  grid-column-gap: 10px;
}

.summaryTableAddon {
  margin-left: 24px;
}

.qaAddonFoodEditorLink {
  vertical-align: middle;
  margin-left: 3px;
}

.qaActionButton:hover {
  cursor: pointer;
}

.view:hover {
  background: rgba(100, 150, 220, 0.4);
  cursor: pointer;
}

.click:hover {
    background: rgba(100, 150, 220, 0.4);
    cursor: pointer;
}

.footer {
  text-align: center;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  padding: 2rem 0rem 2rem 0rem;
}